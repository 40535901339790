<template>
  <div class="container-warp">
    <div class="back-box">
      <back-title>考试详情</back-title>
    </div>
    <div class="detail-box">
      <div class="detail-box-l">
        <com-title>剩余时间</com-title>
        <div class="time-box">{{ `${hr}: ${min}: ${sec}` }}</div>
        <div style="display: flex; align-items: center; margin-bottom: 15px">
          <com-title>答题卡</com-title>
          <div style="font-size: 12px; color: #666666">
            可点击序号进行快速定位
          </div>
        </div>
        <!-- 题目序号--开始 -->
        <div class="quick-position">
          <div v-for="(questionsGroup,questionIndex) in questionsGroupList" :key="questionIndex" class="quick-position-content">
            <div v-if="questionsGroup.groupName" class="position-group-title">{{questionsGroup.groupName}}</div>
            <div class="position-group-content">
              <div v-for="(topicItem, topicIndex) in questionsGroup?.questionInfo" :key="topicIndex" class="position-number" @click="_quickPositionBtn(questionIndex,topicIndex)">
                {{topicIndex+1}}
              </div>
            </div>
          </div>
        </div>
        <!-- 题目序号--结束 -->
      </div>
      <div class="detail-box-r">
        <el-scrollbar style="height: 730px; overflow: hidden">
          <div class="test-paper-title">试卷题目</div>
          <div  v-if="questionsGroupList?.length">
            <div v-for="(questionsGroup,groupIndex) in questionsGroupList" :key="groupIndex">
              <!--题目分组名称--开始  -->
              <div v-if="questionsGroup.groupName">
                <div style="display:flex; justify-content:space-between;margin-bottom:16px;">
                    <com-title :title="questionsGroup.groupName" />
                </div>
              </div>
              <!--题目分组名称--结束  -->
              <!-- 题目-开始 -->
              <div
                class="test-paper-item"
                v-for="(item, topicIndex) in questionsGroup?.questionInfo||[]"
                :id="`topicLink${questionsGroup?.groupIndex}${topicIndex}`"
                :key="topicIndex"
              >
                <div class="test-paper-item-title">
                  <span>{{ topicIndex + 1 }}.</span
                  ><span style="font-size: 14px; color: #0089ff; font-weight: 400"
                    >「{{ getTypeValue(item.questionType) }}」</span
                  ><span>{{ item.title }}</span
                  ><span style="font-size: 12px; color: #666666; font-weight: 400"
                    >（{{ item.score }}分）</span
                  >
                </div>
                <div class="problem-description">
                  题目说明：{{ item.content }}
                </div>
                <!-- 题目说明---上传图片 开始-->
                <div v-if="item?.contentUrl!==''&&item?.contentUrl.length!==0&&item?.contentUrl[0]!==''" style="margin-bottom:16px;">
                  <el-image v-for="(item,index) in item?.contentUrl" :key="index" :src="item" style="margin-right:10px;width:100px;height:100px;" :preview-src-list="[item]"></el-image>
                </div>
                <!-- 题目说明---上传图片--结束 -->
                <div class="result-box">
                  <div v-if="item.questionType === 'SINGLE_CHOICE_TYPE'||item.questionType === 'JUDGMENT_TYPE'">
                    <el-radio-group class="radio-box" v-model="item.answer">
                      <el-radio
                        v-for="(ele, index) in item?.options"
                        :key="index"
                        :label="`选项${index + 1}`"
                        >{{ ele.optionName }}</el-radio>
                    </el-radio-group>
                  </div>
                  <div v-if="item.questionType ==='MULTIPLE_CHOICE_TYPE'">
                    <el-checkbox-group class="check-box" v-model="item.answerCheck">
                      <el-checkbox
                        v-for="(ele, index) in item.options"
                        :key="index"
                        :label="`选项${index + 1}`"
                        >{{ ele.optionName }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div v-if="item.questionType === 'GAP_FILLING_TYPE'">
                    <div v-for="(fillItem,fillIndex) in item?.fillAnswer" :key="fillIndex">
                      <div class="input-box">
                        <span>填空{{fillIndex+1}}</span>
                        <el-input v-model="fillItem.answer" placeholder="请输入"></el-input>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.questionType === 'ESSAY_QUESTION_TYPE'">
                    <div class="textarea-box">
                      <el-input
                        v-model="item.answer"
                        style="margin-bottom: 12px"
                        type="textarea"
                        autosize>
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 题目-结束 -->
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="footer-box">
      <el-button type="primary" @click="_submitPaperBtn">交卷</el-button>
    </div>
  </div>
</template>

<script>
import { getClickStartExamApi, getSubmitPaperApi } from '@/api/examApi'
// getStudentExamDetailApi
import backTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
import { getCookie, getCurrentTime } from '@/utils/util'
export default {
  components: {
    backTitle,
    comTitle
  },
  data () {
    return {
      uploadAction: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload', // 上传附件 链接
      uploadHeader: {
        token: getCookie('token')
      },
      questionsGroupList: [], // 题目信息
      uploadBgColor: '',
      notDoneTopicNum: 0, // 未做题目数量
      textTip: '确定交卷？',
      submitParams: {},
      noRecordBehavior: false,
      durationTime: 1,
      definedFinishTime: '', // 下边计算为（当前时间加上所需考试时长）为考试截止时间
      remainingTime: '', // 所剩余时间
      reminderTime: 0.5, // 提醒时间
      hr: 3,
      min: 30,
      sec: 0,
      basicInfo: {},
      currentExamInfo: {},
      clickStartAnswerTime: ''
    }
  },
  // vue路由的钩子函数 , 可以放在mouted的同级
  beforeRouteLeave (to, from, next) {
    if (this.noRecordBehavior) { // 交卷保存完成则不提示
      next()
    } else {
      this.$confirm('离开页面 , 试卷会被提交哦！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.submitParams = JSON.parse(JSON.stringify(this._getSubmitPaperParams()))
          this._confirmSubmitPaper(this.submitParams)
        }).catch(() => {})
    }
  },
  created () {
    this.currentExamInfo = JSON.parse(localStorage.getItem('wholeExamTimeInfos'))
    this.clickStartAnswerTime = localStorage.getItem('startAnswerQuestionTime')
    this._getExamCountdownTime()// 获取考试倒计时
    this.uploadBgColor = this._hexToRgb(this.$store.state.settings.theme)
    if (this.$route.query.examInfoId) {
      this._getCurrentExamDetail()
    }
  },
  mounted () {
    document.addEventListener('visibilitychange', this.handleVisiable, false)
  },
  // 页面销毁之前
  beforeDestory () {
    document.removeEventListener('visibilitychange', this.handleVisiable, false)
  },
  // 页面销毁
  destroyed () {
    document.removeEventListener('visibilitychange', this.handleVisiable, false)
  },
  methods: {
    // 监听考生行为
    handleVisiable (e) {
      e = e || window.event
      e.returnValue = '离开此页面？'
      switch (e.target.visibilityState) {
        case 'prerender':
          // console.log('网页预渲染，内容不可见')
          break
        case 'hidden':
          // console.log('内容不可见，处理后台、最小化、锁屏状态')
          this.submitParams = JSON.parse(JSON.stringify(this._getSubmitPaperParams()))
          this._confirmSubmitPaper(this.submitParams)
          break
        case 'visible':
          // console.log('处于正常打开')
          break
      }
    },
    // 获取当前考试详情
    _getCurrentExamDetail () {
      getClickStartExamApi({ examInfoId: this.$route.query.examInfoId }).then((res) => {
        if (res.code === 200) {
          this.questionsGroupList = JSON.parse(JSON.stringify(res.data))
          if (this.questionsGroupList?.length !== 0) {
            this.questionsGroupList.forEach((groupInfo, groupIndex) => {
              this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
            })
          }
        }
      })
    },
    // 试卷预览-- 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex) {
      groupInfo.groupIndex = groupIndex
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          ...topicItem,
          index: topicIndex,
          groupIndex: groupIndex,
          fillingSign: topicItem.title ? (topicItem.title.match(/_/g) || []).length : 0,
          fillAnswer: topicItem.title.split('_').map((item) => { return { answer: '' } }),
          answerCheck: topicItem.answer ? topicItem?.answer?.split(',') : [],
          options: topicItem.options ? JSON.parse(topicItem?.options) : [],
          contentUrl: topicItem.contentUrl ? topicItem?.contentUrl?.split(',') : [],
          analysisUrl: topicItem.analysisUrl ? topicItem?.analysisUrl.split(',') : []
        }
      })
      groupTopicList[groupIndex].questionInfo.forEach((tempItem, tempIndex) => {
        tempItem.fillAnswer.splice(0, 1)
      })
    },
    // 获取交卷参数
    _getSubmitPaperParams () {
      const topicList = JSON.parse(JSON.stringify(this.questionsGroupList))
      const params = {
        examInfoId: Number(this.$route.query.examInfoId),
        examBehavior: 0, // 考试行为
        submitTime: getCurrentTime(), // 考试提交时间
        examTime: this.currentExamInfo.duration, // 考试时长
        answers: []
      }
      topicList.forEach((item) => {
        // console.log(item.questionInfo, 'item.questionInfo')
        item.questionInfo.forEach((topicItemOne) => {
          topicItemOne.fillAnswer = topicItemOne.fillAnswer.map((item) => {
            return item.answer
          })
        })
        item.questionInfo = item.questionInfo.map((topicItem) => {
          return {
            questionId: topicItem.id,
            fillAnswer: topicItem.fillAnswer,
            questionType: topicItem.questionType,
            answer: topicItem.questionType === 'GAP_FILLING_TYPE' ? topicItem.fillAnswer.join('。')// 填空题
              : topicItem.questionType === 'SINGLE_CHOICE_TYPE' ? topicItem.answer// 单项选择题
                : topicItem.questionType === 'MULTIPLE_CHOICE_TYPE' ? (topicItem.answerCheck ? topicItem.answerCheck.join(',') : '')// 多项选择题
                  : topicItem.questionType === 'JUDGMENT_TYPE' ? topicItem.answer// 判断题
                    : topicItem.questionType === 'ESSAY_QUESTION_TYPE' ? topicItem.answer : '', // 问答题
            questionPoints: topicItem.score

          }
        })
        params.answers.push.apply(params.answers, item.questionInfo)
      })
      this.notDoneTopicNum = this._judgeTopic(params.answers)
      return params
    },
    // 考试结果
    _submitPaperBtn () {
      this.submitParams = JSON.parse(JSON.stringify(this._getSubmitPaperParams()))
      this.textTip = this.notDoneTopicNum !== 0 ? `您还有${this.notDoneTopicNum}道题未答，确定交卷？` : '您已完成所有题目，确定交卷？'
      // 计算还未批阅的题目notDoneTopicNum数量
      this.$confirm(this.textTip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 计算考试所用时长（交卷时间-点击开始答题时间）
        const submitPaperTime = new Date(getCurrentTime()).getTime()// 交卷时间
        const clickAnswerTime = new Date(this.clickStartAnswerTime).getTime()// 点击答题的时间
        this.submitParams.examTime = this._convertTimeToMinutes(submitPaperTime - clickAnswerTime)
        this._confirmSubmitPaper(this.submitParams)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 调用交卷接口
    _confirmSubmitPaper (params) {
      getSubmitPaperApi({ ...params }).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '提交成功!'
          })
          this.noRecordBehavior = true
          this.$router.push({
            path: '/endOfExamination',
            query: {
              examInfoId: this.$route.query.examInfoId,
              employeeId: this.$store.state.userList.userId
            }
          })
        }
      })
    },
    // 判断是否有题目未做数量
    _judgeTopic (topicList) {
      const allTopicList = JSON.parse(JSON.stringify(topicList))
      let noDoneNumber = 0
      // 计算是否有题目未做数量
      allTopicList.forEach((topicItem, topicIndex) => {
        // 未批阅题目数量
        if (topicItem.answer === null || topicItem.answer === '' || topicItem.answer === undefined) {
          noDoneNumber++
        }
        // console.log(topicItem.fillAnswer, topicItem.answer, String(topicItem.answer).length, topicItem.questionType, 'topicItem.questionType')
        // 填空题-计算是否答题
        if (topicItem.fillAnswer.length === String(topicItem.answer).length + 1 && topicItem.questionType === 'GAP_FILLING_TYPE') {
          noDoneNumber++
        }
      })
      return noDoneNumber
    },
    // 快速定位
    _quickPositionBtn (questionIndex, topicIndex) {
      const dom = document.getElementById(`topicLink${questionIndex}${topicIndex}`)
      dom.scrollIntoView(true)
      const scrolledY = window.scrollY
      if (scrolledY) {
        window.scroll(0, scrolledY - 60) // 防止顶部有悬浮窗
      }
    },
    // 获取考试倒计时
    _getExamCountdownTime () {
      this._canUseExamTotalTime()// 考生能使用的考试时间
      // 点击按钮后开始计算指定长度的时间
      this.definedFinishTime = (Date.parse(new Date()) + this.remainingTime)//
      this._convertMinutesToHMS(this.remainingTime)// 将时间转换成时分秒
      this.countdown()// 开始执行倒计时
    },
    // 计算考生能考试的时间
    _canUseExamTotalTime () {
      const setExamTotalTime = this.currentExamInfo.duration * 60 * 1000// 考试设置的总考试时间（毫秒）
      const examEndTime = new Date(this.currentExamInfo.endTime).getTime()// 考试设置的截止时间
      const currentTime = new Date(getCurrentTime()).getTime()// 当前时间
      const clickAnswerTime = new Date(this.clickStartAnswerTime).getTime()// 点击答题的时间
      const tempCountTime = currentTime - clickAnswerTime// 计算当前时间-点击答题时间
      const differentTime = examEndTime - currentTime
      // 如果 考试设置的截止时间-当前时间 大于总考试时间---正常计时
      if (differentTime >= setExamTotalTime) {
        this.remainingTime = setExamTotalTime - tempCountTime
      }
      // 如果 考试设置的截止时间-当前时间 小于总考试时间---(考试设置的截止时间-当前时间）的差值进行计时
      if (differentTime < setExamTotalTime) {
        this.remainingTime = differentTime
      }
    },
    // 开始执行倒计时
    countdown () {
      const end = this.definedFinishTime // 定义结束时间
      const now = Date.parse(new Date()) // 获取本地时间
      const msec = end - now // 定义总共所需的时间
      // 将时间戳进行格式化
      const hr = parseInt((msec % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const min = parseInt((msec % (1000 * 60 * 60)) / (1000 * 60))
      const sec = (msec % (1000 * 60)) / 1000
      // 倒计时结束时的操作
      const that = this
      if (this.hr == 0 && this.min == 0 && this.sec == 0) {
        this.submitParams = JSON.parse(JSON.stringify(this._getSubmitPaperParams()))
        this._confirmSubmitPaper(this.submitParams)// 到时间自动交卷
        console.log('时间已经结束，答题完毕')
      } else {
        // 如时间未归零则继续在一秒后执行
        this.hr = hr > 9 ? hr : '0' + hr
        this.min = min > 9 ? min : '0' + min
        this.sec = sec > 9 ? sec : '0' + sec
        setTimeout(that.countdown, 1000)
      }
    },
    // 将考试总时长（分钟转换成时分秒）
    _convertMinutesToHMS (minutes) {
      this.hr = Math.floor(minutes / 60)
      this.min = minutes % 60
      this.sec = this.min * 60
    },
    // 讲毫秒转成分钟
    _convertTimeToMinutes (tempTime) {
      const hours = parseInt((tempTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = parseInt((tempTime % (1000 * 60 * 60)) / (1000 * 60))
      // const seconds = (tempTime % (1000 * 60)) / 1000
      if (hours) {
        return hours * 60 + minutes
      } else {
        return minutes
      }
    },
    // 题目类型
    getTypeValue (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          break
      }
      return value
    },
    // 悬浮颜色
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      this.$forceUpdate()
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.container-warp {
  background-color: #ffffff;
  border-radius: 4px;
  height: 88vh;
}
.back-box {
  padding: 16px 24px;
  border-bottom: 1px solid #f3f3f3;
  /deep/.title-box {
    margin-bottom: 0px;
  }
}
.detail-box {
  display: flex;
  .detail-box-l {
    padding: 16px 12px;
    width: 250px;

    border-right: 1px solid #f3f3f3;
    .time-box {
      margin: 12px 0 24px;
      font-size: 20px;
      font-weight: 500;
      color: #0089ff;
    }
  }
  .detail-box-r {
    flex: 1;
    padding: 16px 24px;
    height: 75vh;
    overflow: hidden;
    .test-paper-title {
      padding: 12px 0;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      background: linear-gradient(to bottom, #f0f8ff, #ffffff);
      border: 1px solid #e7f4ff;
      border-radius: 4px;
    }
    .test-paper-item {
      margin-bottom: 32px;
      border-bottom: 1px solid #f3f3f3;
      .test-paper-item-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .problem-description {
        margin-bottom: 12px;
        font-size: 14px;
        color: #999999;
      }
      .result-box {
        margin-bottom: 8px;
        /deep/.radio-box.el-radio-group {
          display: flex;
          flex-direction: column;
          .el-radio {
            margin-bottom: 16px !important;
          }
        }
        /deep/.check-box.el-checkbox-group {
          display: flex;
          flex-direction: column;
          .el-checkbox {
            margin-bottom: 16px !important;
          }
        }
        .input-box {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          span {
            display: inline-block;
            margin-right: 15px;
            width: 50px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.9);
          }
        }
        .textarea-box {
          margin-bottom: 16px;
          /deep/.el-upload--picture-card {
            background: var(--uploadBgColor) !important;
            border-color: var(--uploadBorderColor) !important;
            width: 90px;
            height: 32px;
          }
          /deep/.el-upload-dragger {
            width: 90px !important;
            height: 32px !important;
            background-color: transparent;
            border: 1px dashed transparent;
            .upload-box {
              height: 100% !important;
              display: flex;
              align-items: center;
              justify-content: center;
              .el-icon-plus {
                font-size: 17px;
              }
            }
          }
          /deep/.el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
          }
          /deep/.el-upload-list--picture-card .el-progress {
            width: 90px !important;
          }
        }
      }
    }
  }
}
.footer-box {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.02);
}
  // 左侧快速定位
  .quick-position{
    .quick-position-content{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .position-group-title{
        color: rgba(0,0,0,0.6);
        margin-bottom: 4px;
      }
      .position-group-content{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        .position-number{
          width: 39px;
          height: 26px;
          margin-right:8px ;
          text-align: center;
          line-height: 26px;
          color: #4D4D4D;
          border:1px solid #DCDCDC;
          border-radius: 4px;
          margin-bottom: 8px;
        }
      }
    }
  }
</style>
